// @file
// Custom scripts file
(function() {
  'use strict';

  // no-js => js body class
  document.body.classList.remove('no-js');
  document.body.classList.add('has-js');

  // Generic function that runs on window resize.
  // An empty function is allowed here because it's meant as a placeholder,
  // but you should remove this functionality if you aren't using it!
  // eslint-disable-next-line no-empty-function
  function resizeStuff() {
    document.body.classList.remove('has-mobile-menu-open');
  }

  // Runs function once on window resize.
  let timeOut = false;
  window.addEventListener('resize', () => {
    if (timeOut !== false) {
      clearTimeout(timeOut);
    }

    // 200 is time in miliseconds.
    timeOut = setTimeout(resizeStuff, 200);
  });
  /* eslint-disable */

  window.fakeIsLegalForLocal = () => {
    Cookies.set('ISLEGAL', 'faked', {
      expires: 1,
      path: '/',
      sameSite: 'strict',
      secure: true,
    });
  };
  ///// Mobile Menu Trigger /////
  const mmtClicked = evt => {
    evt.preventDefault();
    document.body.classList.toggle('has-mobile-menu-open');
    hideHeader();
    return false;
  };
  const mmt = document.getElementsByClassName('mobile-menu-toggle-link')[0];
  mmt.addEventListener('click', mmtClicked);

  ///// Main Menu Toggle Subnav /////
  const openSubnav = evt => {
    evt.preventDefault();
    if (!evt.target.parentNode.classList.contains('subnav-show')) {
      evt.target.parentNode.parentNode.classList.add('has-subnav-open');
      mainMenuSubnavToggles.forEach(element => {
        element.parentNode.classList.remove('subnav-show');
      });
      evt.target.parentNode.classList.add('subnav-show');
      // see showHeader below
      showHeader();
    } else {
      evt.target.parentNode.parentNode.classList.remove('has-subnav-open');
      evt.target.parentNode.classList.remove('subnav-show');
    }
  };
  const closeSubnav = () => {
    const hasOpenEls = document.querySelectorAll('.has-subnav-open');
    hasOpenEls.forEach(el => {
      el.classList.remove('has-subnav-open');
    });
    const subnavShowEls = document.querySelectorAll('.subnav-show');
    subnavShowEls.forEach(el => {
      el.classList.remove('subnav-show');
    });
  };
  const mouseBreakpoint = 650;

  const openSubNavFromHover = evt => {
    if (window.innerWidth >= mouseBreakpoint) {
      if (!evt.target.parentNode.classList.contains('subnav-show')) {
        openSubnav(evt);
      }
    }
  };

  const mainMenuSubnavToggles = document.getElementsByClassName(
    'subnav-toggle'
  );
  mainMenuSubnavToggles.forEach(element => {
    element.addEventListener('click', openSubnav);
    element.addEventListener('mouseover', openSubNavFromHover);
  });
  ///// Hover for main menu subnav /////

  ///// Front Page Header Hover/exand  /////
  const showHeader = () => {
    document.body.classList.add('show-header');
  };
  const hideHeader = () => {
    document.body.classList.remove('show-header');
  };
  const checkMouse = evt => {
    if (!document.body.classList.contains('has-mobile-menu-open')) {
      if (evt.clientY < headerThreshold) {
        showHeader();
      } else {
        hideHeader();
        if (window.innerWidth >= mouseBreakpoint) {
          closeSubnav();
        }
      }
    }
  };
  // const headerEl = document.getElementsByClassName('l-header')[0];
  // const headerThreshold = headerEl.offsetTop + headerEl.offsetHeight;
  const toolbarOffset = document.body.classList.contains('toolbar-fixed')
    ? 39
    : 0;
  const toolbarTrayOffset = document.body.classList.contains(
    'toolbar-tray-open'
  )
    ? 40
    : 0;
  const headerThreshold = toolbarOffset + toolbarTrayOffset + 185;

  // console.log(`headerThreshold : ${headerThreshold}`);
  document.addEventListener('mousemove', checkMouse);
  // headerEl.addEventListener('mouseover', showHeader);
  // headerEl.addEventListener('mouseout', hideHeader);

  ///// Modal functions /////
  const initModals = context => {
    const modals = context.querySelectorAll('.fmp-modal__modal-data');
    const modalsLength = modals.length;
    // inital setup if there are modals on screen
    if (modalsLength > 0 && !document.body.contains(document.getElementById('fmp-modal'))) {
      document.body.insertAdjacentHTML('beforeend', '<section id="fmp-modal" class="fmp-modal fmp-modal--closed"><div class="fmp-modal__backdrop fmp-modal--trigger-close" aria-hidden="true">  <div class="fmp-modal__window"><div class="fmp-modal__inner">  </div><button class="fmp-modal__close fmp-modal--trigger-close">X</button></div></div></section>');
    }
    // make close buttons active
    const closeTriggers = document.body.querySelectorAll('.fmp-modal--trigger-close');
    closeTriggers.forEach(trigger => {
      trigger.addEventListener('click', () => {
        closeActiveModal();
      });
    });
    //
    for (let index = 0; index < modalsLength; index++) {
      const modalData = modals[index];
      // first move from inline to hidden
      modalData.classList.remove('fmp-modal--inline');
      modalData.classList.add('fmp-modal--closed');
    }
  };
  initModals(document);

  // close modal function
  const closeActiveModal = () => {
    const activeModal = document.getElementById('fmp-modal');
    activeModal.querySelector('.fmp-modal__inner').innerHTML = 'no content at this time';
    activeModal.classList.remove('fmp-modal--open');
    activeModal.classList.add('fmp-modal--closed');
  };
  const getModalData = node => {
    // evt might be triggered by child
    const target = node.dataset.fmpModalTarget;
    if (target == undefined && node.parentNode != document.body) {
      return getModalData(node.parentNode);
    } else {
      return document.getElementById(target);
    }
  }
  // activate triggers
  const initModalTriggers = context => {
    const triggers = context.querySelectorAll('.fmp-modal--trigger');
    triggers.forEach(trigger => {
      trigger.addEventListener('click', evt => {
        const modalData = getModalData(evt.target);
        const modal = document.getElementById('fmp-modal');
        // copy in content
        modal.querySelector('.fmp-modal__inner').innerHTML = modalData.innerHTML;
        // open modal
        modal.classList.remove('fmp-modal--closed');
        modal.classList.add('fmp-modal--open');
        // trigger image system load
        window.triggerLazyLoad(modal);
      });
    });
  };
  initModalTriggers(document);
  /* eslint-disable */

  ///// Front Page Time and Weather AJAX functions /////
  const updateTime = timeString => {
    const frontBottomRightTime = document.getElementsByClassName('front-page__time');
    if (frontBottomRightTime && frontBottomRightTime.length > 0) {
      frontBottomRightTime[0].innerHTML = timeString;
    }
  };
  const updateTimeSuffix = timeSuffix => {
    const frontBottomRightTimeSuffix = document.getElementsByClassName('front-page__time-suffix');
    if (frontBottomRightTimeSuffix && frontBottomRightTimeSuffix.length > 0) {
      frontBottomRightTimeSuffix[0].innerHTML = timeSuffix;
    }
  };

  const getTimeFromResponse = response => {
    let timeString;
    // example: "2020-10-03T07:33:14.805142+09:30"
    timeString = response.datetime;
    // pull out just hour/minute block
    let timeSubString = timeString.substring(11,16);
    if (timeSubString.substring(0,1) == "0") {
      timeSubString = timeSubString.substring(1);
    }
    // console.log(timeSubString);
    return timeSubString;
  };

  const getTimeAjax = () => {
    const xhr = new XMLHttpRequest();
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4 && xhr.status == 200) {
        // parse JSON
        const data = JSON.parse(xhr.responseText);
        // get time string
        const timeString = getTimeFromResponse(data);
        // update time
        updateTime(timeString);
        updateTimeSuffix(data.abbreviation);
      } else if (xhr.status >= 400) {
        // clear out current text
        updateTime('');
      }
    };
    xhr.addEventListener('error', evt => {
      updateTime(' ');
    });
    xhr.open("GET", "https://worldtimeapi.org/api/timezone/Australia/Adelaide/");
    xhr.send();
  };
  if (document.getElementsByClassName('front-page__time').length >0) {
    getTimeAjax();
  }

  const updateTemp = tempString => {
    document.querySelector('.front-page__temp').innerHTML = tempString;
  };

  const getTempAjax = () => {
    const xhr = new XMLHttpRequest();
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4 && xhr.status == 200) {
        const data = JSON.parse(xhr.responseText).data;
        // console.log(data);
        // update temp on screen
        updateTemp(data.air_temp);
      }
    }

    xhr.open("GET", "/localapi/getweather/json");
    xhr.send();
    // console.log('init temp call');
  };
  if (document.getElementsByClassName('front-page__temp').length > 0) {
    getTempAjax();
  };

  ///// Interactive Map
  const activateMapLayer = id => {
    const layer = document.getElementById(id);
    const buttonQuery = '#' + id + '-toggle button';
    const button = document.querySelector(buttonQuery);
    layer.classList.add('show-map-layer');
    button.setAttribute('aria-pressed', 'true');
  };

  const deactivateMapLayer = id => {
    const layer = document.getElementById(id);
    const buttonQuery = '#' + id + '-toggle button';
    const button = document.querySelector(buttonQuery);
    layer.classList.remove('show-map-layer');
    button.setAttribute('aria-pressed', 'false');
  };

  const toggleButton = evtOrString => {
    // check if event or string, get proper object
    let el;
    if (typeof evtOrString == 'STRING') {
      el = document.querySelector(evtOrString + ' button');
    } else {
      el = evtOrString.target.closest('button');
    }
    const isPressed = el.getAttribute('aria-pressed') === 'true';
    const id = el.parentNode.dataset.toggleTrigger;
    if (isPressed) {
      deactivateMapLayer(id);
    } else {
      activateMapLayer(id);
    }
    //console.log(el);
  };

  const toggleAll = isOn => {
    // toggle map layers
    const layers = document.querySelectorAll('.hcv-map-layer');
    layers.forEach(el => {
      el.classList.toggle('show-map-layer', isOn);
    });
    // forceDisplayUpdate();
    // toggle legend buttons
    const btns = document.querySelectorAll('.fmp-toggle-button__button');
    btns.forEach(el => {
      el.setAttribute('aria-pressed', String(isOn));
      //console.log(el);
    });
  };

  const toggleAllTrue = () => {
    toggleAll(true);
  };
  const toggleAllFalse = () => {
    toggleAll(false);
  };

  const legendButtons = document.querySelectorAll('.hcv-map__legend .fmp-toggle-button__button');
  legendButtons.forEach(btn => {
    btn.addEventListener('click', toggleButton);
  });
  document.querySelectorAll('.hcv-map__control--show-all button').forEach(el => {
    el.addEventListener('click', toggleAllTrue);
  });
  document.querySelectorAll('.hcv-map__control--show-none button').forEach(el => {
    el.addEventListener('click', toggleAllFalse);
  });

  ///// Domain Picker JS /////
  const createDomainPickerButton = currentSiteString => {
    // console.log(`creating button with text ${ currentSiteString }`);
    const wrappers = document.querySelectorAll('.block--id-domain-nav-block .block__content');
    const button = document.createElement("button");
    button.classList.add('domain-popup__trigger', 'button--secondary');
    button.innerHTML = currentSiteString;
    wrappers.forEach(wrapper => {
      wrapper.insertBefore(button.cloneNode(true), wrapper.childNodes[0]);
    });

    activateDomainButtons();
  };
  const activateDomainButtons = () => {
    const domainPopupTriggers = document.querySelectorAll('.domain-popup__trigger');
    domainPopupTriggers.forEach(trigger => {
      trigger.addEventListener('click', toggleDomainPopup);
    });
  };
  const toggleDomainPopup = evt => {
    evt.target.closest('.block--id-domain-nav-block').classList.toggle('show-domain-popup');
  };

  const getCurrentDomain = () => {
    const activeLink = document.querySelector('.menu--domain-nav a.is-active-trail');
    return activeLink.innerHTML;
  };

  const initDomainMenu = () => {
    // check if domain picker exists, then init process
    if (document.getElementsByClassName('block--id-domain-nav-block').length > 0) {
      const currentDomain = getCurrentDomain();
      createDomainPickerButton(currentDomain);
    }
  };
  initDomainMenu();

  ///// Domain Switcher/Set Cookie
  const getPreferredDomainCookieName = () => {
    return 'jfe_preferred_domain';
  };
  const setPreferredDomainCookie = domainCode => {
    console.log(domainCode);
    const expdays = 365;
    if (typeof Cookies !== 'undefined') {
      Cookies.set(getPreferredDomainCookieName(), domainCode, { expires: expdays, path: '/', sameSite: 'strict', secure: true});
    } else {
      setTimeout(setPreferredDomainCookie, 50, domainCode);
    }
  };
  // setup domain menu
  const domainLinks = document.querySelectorAll('.menu--domain-nav a');
  domainLinks.forEach( domainLink => {
    const domainCode = domainLink.firstChild.nodeValue.substring(0,2);
    // append to link
    domainLink.href = domainLink.href + '?preferred_domain=' + domainCode;
    domainLink.addEventListener('click', evt => {
      const linkDomainCode = evt.target.href.slice(-2);
      setPreferredDomainCookie(linkDomainCode);
    });
  });
  const getPreferredDomain = domainCode => {
    if (domainCode !== 'US' && domainCode !== 'AU') {
      domainCode = 'AU';
    }
    const env = getCurrentEnvironment();
    let domains = {
      US : 'https://www.hickinbothamwines.com',
      AU : 'https://www.hickinbothamwines.com.au'
    };
    if ('dev' == env) {
      domains = {
        US : 'https://dev.hickinbothamwines.com',
        AU : 'https://dev.hickinbothamwines.com.au'
      };
    } else if ('test' == env) {
      domains = {
        US : 'https://test.hickinbothamwines.com',
        AU : 'https://test.hickinbothamwines.com.au'
      };
    } else if ('local' == env) {
      domains = {
        US : 'https://hickinbotham-2020.pantheonlocal.com/',
        AU : 'https://hickinbotham-2020.pantheonlocal.com/'
      };
    }
    return domains[domainCode];
  };

  const getCurrentEnvironment = () => {
    console.log(`getting environment from ${window.location.hostname}`);
    if (window.location.hostname.includes('dev')) {
      return 'dev';
    } else if(window.location.hostname.includes('test')) {
      return 'test';
    } else if(window.location.hostname.includes('local')) {
      return 'local';
    } else {
      return 'live';
    }
  };

  const preferredDomainShouldBeActive = () => {
    const env = getCurrentEnvironment();
    if (env == 'dev' || env == 'test' || env == 'live' ) {
      return true;
    }
    return false;
  };

  const geoLocateVisitor = () => {
    console.log('geolocating visitor (active)');
    // get location code using ajax call
    const xhr = new XMLHttpRequest();
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4 && xhr.status == 200) {
        const domainCode = JSON.parse(xhr.responseText).data;
        // console.log(data);
        // update temp on screen
        checkGeocodedDomain(domainCode);
      } else if (xhr.status >= 400) {
        // do nothing
      }
    }
    const cacheBuster = '?cachebuster=' + Date.now();
    xhr.open("GET", "/localapi/geolocate/json" + cacheBuster);
    xhr.send();
    return false;
  };
      // setup reaction for when location code is received

  const checkGeocodedDomain = domainCode => {
    checkRedirectNeeded(domainCode);
  }

  const redirectToPreferredDomain = (domainCode) => {
    console.log(`actually redirecting to ${domainCode} url`);
    const fullUrl = getPreferredDomain(domainCode) + '?preferred_domain=' + domainCode;
    setPreferredDomainCookie(domainCode);
    window.location.assign(fullUrl);
  };

  const checkRedirectNeeded = (preference) => {
    let currentDomainCode = getCurrentDomain().substring(0,2);
    console.log(`checking if needing to redirect from ${currentDomainCode} to ${preference}`);
    // TODO: check current domain code exists, call alternate code to determine current url if the default method fails.
    if (preference != currentDomainCode) {
      redirectToPreferredDomain(preference);
    }
  };

  const checkPreferredDomainCookie = () => {
    console.log('checking domain cookie');
    const domainCode = Cookies.get(getPreferredDomainCookieName());
    if (domainCode) {
      return domainCode;
    } else {
      return false;
    }
  };

  const checkPreferredDomainQueryVar = () => {
    console.log('checking for domain in url query string');
    const queryString = window.location.search;
    if (queryString.length > 0) {
      const queryVars = new URLSearchParams(queryString);
      if (queryVars.has('preferred_domain')) {
        const domainCode = queryVars.get('preferred_domain');
        setPreferredDomainCookie(domainCode);
        return domainCode;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const checkPreferredDomain = () => {
    console.log('checking preferred domain');
    let preference = checkPreferredDomainQueryVar();
    if (preference === false) {
      preference = checkPreferredDomainCookie();
    }
    if (preference === false) {
      preference = geoLocateVisitor();
    }
    if (preference) {
      checkRedirectNeeded(preference);
    }
  };

  if (preferredDomainShouldBeActive()) {
    checkPreferredDomain();
  }

  // confirm cookie
  console.log(getPreferredDomainCookieName() + ' = ' + Cookies.get(getPreferredDomainCookieName()));
  console.log('v047');
})();
